import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel'

export default function WhatCanWeDetect() {
    return (
                    <Box 
                        minHeight="40rem"
                        width="100%"
                        bgcolor="darkgreen.main"
                        variant="gradient"
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: 'linear-gradient( #122418, #07331c)',
                        }}
                    >
                        <Grid container maxWidth="xl" spacing={4}  >
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h2" mb={1} mt={10} color="grey.200">
                                    Capabilities
                                </Typography>

                            </Grid>
                            <Box display="flex" justifyContent="center" >
                                <Grid item xs={12} md={6} textAlign="center">
                                    <Typography variant="h5" mt={5} mb={5} color="grey.200" >
                                        Trawl AI detects active attacks against your cloud assets, allowing you to react quickly and prevent
                                        loss of sensitive information and ransomware.
                                    </Typography>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid container maxWidth="xl" spacing={4}  justifyContent="center" >
                            <Grid item xs={12} lg={6} >
                                <Carousel interval="8000">
                                    <Box 
                                        minHeight="100%"
                                        bgcolor="trawlgreen.main" 
                                        p={4}
                                        sx={{
                                            border: 2,
                                            borderColor: 'warning.main',
                                            borderRadius: 2,
                                            bgcolor: 'darkgreen.focus',
                                            '&:hover': {
                                            bgcolor: 'darkgreen.main',
                                            },
                                        }}
                                    >
                                        <Typography variant="h4" my={1} color="grey.200" textAlign="center" fontWeight="bold" mb={2}>
                                            Account Takeover 
                                        </Typography>
                                        <Typography variant="body1" color="grey.200" mb={2}>The most common attack vector against Microsoft 365 tenants begins with a phishing email.
                                        Attackers use phishing and other email-based attacks to steal credentials and access user accounts.
                                        Trawl AI uses machine learning to model each user and tenant to detect unauthorized access to accounts and productivity applications.
                                            
                                        </Typography>
                                    </Box>
                                    <Box
                                        minHeight="100%"
                                        bgcolor="trawlgreen.main" 
                                        p={4}
                                        sx={{
                                            border: 2,
                                            borderColor: 'warning.main',
                                            borderRadius: 2,
                                            bgcolor: 'darkgreen.focus',
                                            '&:hover': {
                                            bgcolor: 'darkgreen.main',
                                            },
                                        }}
                                        >
                                        <Typography variant="h4" color="grey.200" textAlign="center" fontWeight="bold" mb={2}>
                                            Insider Threats
                                        </Typography>
                                        <Typography variant="body2" color="grey.200" mb={2}>An insider threat is a security risk that originates from within your organization.
                                        Most data breaches occur when a current or former employee, vendor, or third party misuses their access
                                        to the detriment of the organization's networks, systems, and data.
                                        These insiders exploit their access to steal proprietary information, and view and destroy data that can be used for financial gain.
                                        To protect against these threats, Trawl AI goes beyond the typical SIEM. We detect anomalous activity based on behavioral models to catch threats before they can exploit their access.
                                            
                                        </Typography>
                                    </Box>
                                    <Box
                                        minHeight="100%"
                                        bgcolor="trawlgreen.main" 
                                        p={4}
                                        sx={{
                                            border: 2,
                                            borderColor: 'warning.main',
                                            borderRadius: 2,
                                            bgcolor: 'darkgreen.focus',
                                            '&:hover': {
                                            bgcolor: 'darkgreen.main',
                                            },
                                        }}
                                        >
                                        <Typography variant="h4" color="grey.200" textAlign="center" fontWeight="bold" mb={2}>
                                            Cloud Exploits
                                        </Typography>
                                        <Typography variant="body1" color="grey.200" mb={2}>Cloud accounts are accessed through many attack vectors, but once an attacker is in, they can access sensitive data, create virtual servers to mine cryptocurrency,
                                            and use your cloud account to attack others.
                                            Billing alarms can help detect this, however additional security is needed to prevent prolonged access.
                                            Trawl AI builds machine learning models from many data points to determine if unauthorized access to accounts have led to misuse.
                                        </Typography>
                                    </Box>
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Box>
    )
}