import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import noc from 'assets/images/photos/noc.jpg';

// Intelligently detecting unauthorized use and access in your business applications
  
export default function SMBs() {
  useEffect(() =>{
    document.title = 'SMBs - Intelligent Security for Cloud';
    window.scrollTo(0,0)
 })
  return (
    <>
    <Box
      minHeight="30rem"
      width="100%"
      variant="gradient"
      bgcolor="darkgreen.main"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
  <Grid container maxWidth="lg" spacing={2} mt={8} p={2} sx={{ minHeight: "350px"}} >
    <Grid item xs={12} md={6} p={2}>
      <Typography variant="h2" color="white.main" fontWight='fontWeightMedium' mt={4}>
              For Small & Medium Businesses
            </Typography>
            <Typography
              variant="body1"
              color="white.main"
              mt={2}
            >
              Protect your users through rapid identification of stolen credentials, successful phishing attacks, MFA bypass, and more.
            </Typography>
          </Grid>
          <Grid item xs={0} md={6} >
            <Box  style={{
              backgroundImage: `url(${noc})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
            }}> 
              
            </Box>
          </Grid>
        </Grid>
      </Box>
      
    
        <Grid container justifyContent="center" alignItems="center"  p={2} mb={5} mt={10}>
                <Grid item xs={12} md={4} textAlign="">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2}> 
                        Simplify Cloud Defense
                    </Typography>
                    <Typography variant="body" color="dark" mb={10} align="">
                        Trawl AI simplifies the protection of your most sensitive data through automation, machine learning, and artifical intelligence.
                        We detect successful attacks as they happen so access can be mitigated and the destruction and exfiltration of data can be prevented. 
                        
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" p={2} spacing={2} mb={10} mt={10}>
                <Grid item xs={12} md={4} justifyContent="center">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2} >
                        Report User Activity
                    </Typography>
                    <Typography variant="body1" color="dark" mb={4} align="">
                        Our automated system is just one tool in the discovery of threats against your data. We provide the tools necessary to offer proof of 
                        insider threats by error or by intent. 
                    </Typography>
                </Grid>
            </Grid>
     
    </>
  );
}