import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function CustomerSuccessStories() {
    return (

            <Grid container maxWidth="lg" spacing={2} justifyContent="center" sx={{ mx: "auto" }} pb={2} mb={8}>
                <Grid item xs={12} md={4}>
                    <Box pt={3}>
                        <Card sx={{ minWidth: 275,  p: 2, }} elevation={0} style={{ backgroundColor:"transparent"}}>
                            <CardHeader subheader="Medical Research - Raleigh, NC" />
                            <CardContent>"Our company director fell victim to a phishing attack. Thanks to Trawl AI's early detection, our managed services team received the alert and immediately blocked access to his account. 
                                Any delay in detecting that access could have cost us tens of thousands of dollars in lost research and damage to our reputation."
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box>
                        <Card sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            background: 'linear-gradient( #122418, #07331c)',
                            p: 2,
                         }}>
                            <Typography variant="h6" color="grey.100">Managed Services - Raleigh, NC</Typography>
                            <CardContent><Typography variant="body1" color="white.main">"The Trawl AI platform provides access to organized data and collaboration that we were unable to get elsewhere, allowing us to better serve our customer's cybersecurity needs. 
                                We are able to provide evidence of the exfiltration or destruction of data when employees give notice, review best practices across cloud services, and get notified immediately if a client
                                user's account is compromised."</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box  pt={3}>
                        <Card sx={{ minWidth: 275, p: 2, borderRadius: 2, }} elevation={0} style={{backgroundColor:"transparent"}}>
                            <CardHeader subheader="Architecture Design - Durham, NC" />
                            <CardContent>"Sharing large files with clients is a big part of our business, so increased access to files is not something we would typically notice. However, Trawl AI detected an unusual amount of activity 
                                from just a few SharePoint links and notified us that a vendor or customer was likely exploited and the attackers were accessing files. We immediately expired those links and changed policies to better protect our data."</CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
    )
}