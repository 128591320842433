import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
// Intelligently detecting unauthorized use and access in your business applications


function UberCompromise() {
    useEffect(() =>{
        document.title = 'Uber & American Airlines fall victim to Email based attack';
     })
  return (
    <>

        <Container>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={6} textAlign="center">
                    <Typography variant="h4" mb={2} textTransform="capitalize" align="center">
                        Teen hacker gains deep access to systems and posts evidence 
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={6} >
                    <Typography variant="body2" color="black" paragraph>
                        Last week, ride-share company Uber notified its users on Twitter they were responding to a cybersecurity incident and were in touch with law enforcement. 
                        The hacker shared screenshots of internal systems, including Slack, AWS console, and Google Workspace dashboard, as proof they had infiltrated Uber's systems.  
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        The New York Times reported that the threat actor gained access through a social engineering attack on an employee and stealing their password. Uber later 
                        blamed the attack on a contractor, not that the employment status of someone with administrative access to your most sensitive infrastructure should matter.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph> 
                        After stealing the credentials of the employee/contractor, the attacker used a MFA Fatigue attack to complete authentication to the system.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Once the hacker has access to the account, they can update credentials, modify MFA settings, steal information, and infect systems with ransomware. 
                    </Typography>
                    <Typography variant="h5" color="primary" paragraph>
                    "(I was spamming the employee with push auth for over an hour) i then contacted him on WhatsApp and claimed to be from Uber IT, told him if he wants it to stop he must accept it"
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Getting a push notification like this typically means the correct user and password challenge has been provided, then moving to the next authentication factor. If you are receiving push after push from your Microsoft Authenticator or similar application, please change your password immediately and notify your security team.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Yesterday, American Airlines admitted to a data breach affecting employee and customer data, which took place in early July. The company has stated that employee email accounts were compromised in an email phishing campaign, though it was a "very small number."
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        These are just two examples of large enterprise companies being victimized by attackers with email being the entry point. More than 80% of reported attacks start with a phishing email. 
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>
                        How do small & medium business protect themselves when AA and Uber cannot?
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        If these multi-billion dollar companies are so easily victimized, what protections do smaller businesses have? If email spam gateways, anti-virus, multi-factor authentication, and other prevention methods are so easily bypassed, and their security teams are not aware, what hope is there for the rest of us?
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        This is the problem we at Trawl AI identified as SMBs shifted to work-from-home at the beginning of the pandemic. Migration to services like Microsoft 365 and Google Workspace created many new attack vectors for threat actors, without any improvement on prevention methods. Trawl AI compliments existing prevention services by detecting when they are bypassed and access to your user accounts is successful.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        The Trawl AI platform <Typography variant="body2" component="a" href="/platform/microsoft-365">protects Microsoft 365 accounts</Typography> through automated collection of data points that identify how your users are utilizing the product suite and detection of changes that may indicate an active attack. In the above examples of email compromise attacks, Trawl AI would identify the compromised accounts through our sign-in anomaly detection; a custom machine learning algorithm that learns from each user's location, device, and other factors to determine if the sign-in is legitimate.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Microsoft 365 will block logins from known threats by IP address, and other services will look to the country of origin to determine if the sign-in is significant. Only Trawl AI detects true anomalies that indicate an account has been accessed by a threat actor.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        To learn more about Trawl AI and <Typography variant="body2" component="a" href="/platform/microsoft-365">protection for Microsoft 365</Typography>:
                    </Typography>

                    <Typography variant="body2" color="black" paragraph>
                        Reach out today using our <Typography variant="body2" component="a" href="/about/contact-us">contact form</Typography> or register <Typography variant="body2" component="a" href="https://app.trawlai.com/authentication/sign-up" target="_blank">here</Typography> for a free trial
                    </Typography>

                </Grid>
            </Grid>
        </Container>
     
    </>
  );
}

export default UberCompromise;
