import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import colors from "assets/theme/base/colors";
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import computerUser from 'assets/images/photos/computer-user.jpg'

const defaultTheme = createTheme({
  palette: {...colors},
  typography: {
    button: {
      textTransform: 'none'
    },
  },
  img: {
      filter: "brightness(50%)",
  },
}); 

// Intelligently detecting unauthorized use and access in your business applications
  
export default function ManagedPartners() {
  useEffect(() =>{
    document.title = 'MSPs - Intelligent Security for Cloud';
    window.scrollTo(0,0)
 })
  return (
    <>
    <Box
      minHeight="30rem"
      width="100%"
      variant="gradient"
      bgcolor="darkgreen.main"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Grid container maxWidth="lg" spacing={2} p={2} mt={8} sx={{ minHeight: "350px"}} >
        <Grid item xs={12} md={6} p={2} sx={{ minHeight: "300px"}}>
          <Typography variant="h2" color="white.main" mt={2}>
                  Managed Services Providers
                </Typography>
                <Typography
                  variant="h6"
                  color="white.main"
                >
                  Protect your clients through rapid detection of active threats against their cloud assets.
                </Typography>
        </Grid>
        <Grid item xs={0} md={6} >
          <Box  style={{
            backgroundImage: `url(${computerUser})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
          }}> 
            
          </Box>
        </Grid>
      </Grid>
      </Box>
      
    
        <Grid container justifyContent="center" alignItems="center" p={2} mb={5} mt={10}>
                <Grid item xs={12} md={4} textAlign="">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2}> 
                        Uncomplicate Cloud Security
                    </Typography>
                    <Typography variant="body" color="dark" mb={10} >
                        Trawl AI simplifies the protection of your customer's most sensitive data through automation, machine learning, and artifical intelligence.
                        We detect successful attacks as they happen so access can be mitigated and the destruction and exfiltration of data can be prevented.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" p={2} spacing={2} mb={10} mt={10}>
                <Grid item xs={12} md={4} justifyContent="center">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2} >
                        Available Services
                    </Typography>
                    <Typography variant="body1" color="dark" mb={4} >
                        Trawl AI is currently live with Microsoft 365 protection to detect successful phishing campaigns, MFA exhaustion attacks, transparent proxy
                        hacks, and supply chain attacks against your customers. 
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" p={2} spacing={2} mb={10} mt={10}>
                <Grid item xs={12} md={4} justifyContent="center">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2} >
                        Get Protected In Minutes
                    </Typography>
                    <Typography variant="body1" color="dark" mb={2} >
                        It takes only a few minutes to subscribe and start collecting data for your customers. We are offering a free 30 day trial for up to three 
                        Microsoft 365 tenants. <Typography component={Link} to="/contact-us" color="darkgreen.main">Contact us today</Typography> for more information or to schedule a demo.
                    </Typography>
                    <Link to="https://app.trawlai.com/sign-up" target="_blank" >
                      <Button 
                          sx={{ marginTop: '50px', marginRight: '50px'}}
                          id="learnmore-button"
                          disableElevation
                          color="darkgreen"
                          key="learnmore"
                          variant="contained" 
                          size="lg">
                              <Typography sx={{ fontSize: "1.3em" }} color="white.main">Start 30-Day Trial</Typography>
                      </Button>
                      </Link>
                </Grid>
            </Grid>
            
    </>
  );
}

