import { useState, useEffect } from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import dataProtect from 'assets/images/illustrations/data-protection.png'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CounterCard from './CounterCard';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function IndexPageHeader() {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
            <>
                    <Box 
                        width="100%"
                        bgcolor="darkgreen.main"
                        variant="gradient"
                        sx={{
                            height: isMobile ? '100%' : '100vh',
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: 'linear-gradient( #07331c, #122418)',
                        }}
                    >
                        <Grid container maxWidth="lg" spacing={2} ml={5} mt={2}>
                            <Grid item xs={12} md={7} p={2} >
                                <Typography variant="h2" color="white.main" fontWight='fontWeightMedium'>
                                    Bringing Intelligence to Cybersecurity
                                </Typography>
                                <Typography variant="h4" color="grey.300" pt={2}>
                                    Discover compromised accounts before data can be stolen or destroyed
                                </Typography>
                                <Typography  variant="h6" color="grey.400" component="h3" sx={{ fontStyle: 'italic', mt: 4, p:1 }}>
                                    Trawl AI <Typography variant="h5" component="h3" color="warning.main" display="inline">changes the approach to cyber-security </Typography>
                                     in the cloud, protecting your Microsoft 365 products and (soon!) your entire cloud portfolio from internal and external threats.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5} sx={{ alignItems: "right"}}>
                                <img src={dataProtect} alt="Trawl AI Security Dashboard" width="80%" /> 
                            </Grid>
                        </Grid>
                        <Grid container maxWidth="lg" spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Card sx={{ minWidth: 275, background: 'linear-gradient( #0f4d2d, #122418)' }} >
                                    <CardContent>
                                            <CounterCard 
                                                color="warning.main"
                                                count={90}
                                                suffix="%"
                                                title="of Cyber-Attacks"
                                                description="Start with a phishing email to steal credentials" 
                                            />
                                    </CardContent>
                                    
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card sx={{ minWidth: 275, background: 'linear-gradient( #0f4d2d, #122418)' }} >
                                    <CardContent>
                                        <CounterCard 
                                                color="warning.main"
                                                count={500}
                                                suffix="k"
                                                title="Microsoft 365 Accounts"
                                                description="Compromised in 2021, according to Barracuda" 
                                            />
                                    </CardContent>
                                    
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card sx={{ minWidth: 275, background: 'linear-gradient( #0f4d2d, #122418)' }} >
                                    <CardContent>
                                    <CounterCard 
                                                color="warning.main"
                                                count={37}
                                                suffix="%"
                                                title="of businesses affected"
                                                description="They paid out $20 billion in ransomware in 2021" 
                                            />  
                                    </CardContent>
                                  
                                </Card>
                            </Grid>
                        </Grid>
                        
                    </Box>
                    <Box 
                        minHeight="20rem"
                        width="100%"
                        variant="gradient"
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: '#0f4d2d',
                        }}
                    >
                    <Grid container maxWidth="lg" spacing={2}>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={8} sx={{ placeItems: "center" }}>
                            <Typography align="center" variant="h4" color="white.main">Currently, it takes <Typography color="grey.400" variant="h4" sx={{ fontStyle: 'italic', mt: 4 }} display="inline"> on average </Typography>
                            240 days to detect a breach. That's almost EIGHT MONTHS! </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </>

    )
}