import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
// Intelligently detecting unauthorized use and access in your business applications


export default function CriticalInfrastructure() {
  return (
    <>

        <Container>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={10} textAlign="center">
                    <Typography variant="h3" mb={2} textTransform="capitalize" align="center">
                        {document.title}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={6} textAlign="center">
                    <Typography variant="h5" mb={2} textTransform="capitalize" align="center">
                        Attacks on industries identified as critical are increasing and getting more sophisticated
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={10}>
                <Grid item xs={12} md={6} >
                    <Typography variant="body2" color="black" paragraph>
                        CISA, the Cybersecurity & Infrastructure Security Agency, has identified energy and communications systems as uniquely critical
                        due to the enabling functions they provide across all sectors, but many other industries are also deemed critical that support
                        these primary categories. 
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Chemical manufacturing, dams, food and agriculture, emergency services, and many more have been identified by CISA as Critical Infrastructure and all are 
                        primary targets of private and state-sponsored hacking teams intending to disrupt essential services or ransom access to control 
                        systems, sensitive data, and other services. 
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Intrusion detection and other safeguards are not as effective as they once were and other defenses, such as ransomware detection, 
                        only identify certain types of attacks. While ransomware is a significant problem, groups like Karakurt Data Extortion Group are not
                        encrypting files and data, they are instead accessing and exfiltrating sensitive data to extort payment from businesses, where the payment
                        prevents release of the information to the public.
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>
                     "Cyber-attackers will always look for the weakest point of entry and mitigating third-party risk is critical for cybersecurity. "
                       <br /><br />
                        <Typography variant="h6" color="primary" component="a" href="https://www.forbes.com/sites/chuckbrooks/2022/11/23/a-boiling-cauldron-cybersecurity-trends-threats-and-predictions-for-2023/?sh=2d640bb5143d" target="_blank">Forbes, A Boiling Cauldron: Cybersecurity Trends, Threats, And Predictions For 2023</Typography>
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        These groups are finding new ways to avoid detection and extorting anywhere from $25,000 to $13,000,000 in Bitcoin from their victims. 
                        Their rapid innovation of tools and methods to gain access to their victim's systems has made prevention nearly impossible and detection of
                        certain types of activities changes too quickly to be sufficiently accurate. A new method of detection is necessary, and Trawl AI's automated
                        security intelligence system has protected our client's sensitive data and information throughout the Microsoft 365 productivity suite.
                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        Trawl AI collects usage information from Microsoft 365 to build comprehensive profiles of companies and their users, creates time series data
                        from dozens of trend points, and uses custom machine learning algorithms to detect when a pattern changes that may indicate an account has
                        been accessed by a threat actor. <br /><br /> We provide true sign-in location anomaly detection while other services look only for known hacking locations.
                        We offer accurate data exfiltration detection from OneDrive, Email, and SharePoint. We have successfully detected supply chain attacks against
                        third parties that were accessing shared information in a protected tenant.
                    </Typography>
                   
                    <Typography variant="body2" color="black" paragraph>
                        Hacking groups have successfully targeted Uber, Microsoft, Shields Health Care, nVidia, Marriot, and many more. These companies have
                        enormous IT and cybersecurity budgets and still fall victim to email compromise, social engineering, supply chain, and other attacks, so how 
                        does a small or medium sized business protect itself? Start with protecting your productivity suite services in the cloud. Trawl AI offers immediate
                        notification of email compromise, data exfiltration, and data deletion from external hacks or authorized accounts intent on damaging your company and
                        its data.
                    </Typography>
                    <Typography variant="h6" color="black" paragraph>"In 2022, it took an average of 277 days—about 9 months—to identify and contain a breach. Shortening the time it takes to identify and contain a data breach to 200 days or less can save money."
                        <br /><br /><Typography variant="h6" color="primary" component="a" href="https://www.ibm.com/reports/data-breach" target="_blank">- IBM, Cost of a Data Breach</Typography>
                    </Typography>
                    <Typography variant="body2" color="black" paragraph >
                        We offer a cost-effective solution that protects your Microsoft 365 tenant against a number of attack vectors and reduces that time of 
                        the detection of a hack from an average of <em><strong>277 days</strong></em> to <strong>minutes</strong>.

                    </Typography>
                    <Typography variant="body2" color="black" paragraph>
                        If your industry has been identified as critical by CISA it's a matter of when, not if, you will be the target of a sophisticated attack.                        
                        Reach out today using our <Typography variant="body2" color="primary" component="a" href="/about/contact-us">contact form</Typography> to learn more about 
                        how Trawl AI can protect your Microsoft 365 tenant using automated security intelligence.
                    </Typography>

                </Grid>
            </Grid>
        </Container>
     
    </>
  );
}


