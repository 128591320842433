import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import colors from "assets/theme/base/colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import amazonAWS from 'assets/images/illustrations/amazon-aws.png';

const defaultTheme = createTheme({
    palette: {...colors},
    typography: {
      button: {
        textTransform: 'none'
      },
    },
    img: {
        filter: "brightness(50%)",
    },
}); 

// Intelligently detecting unauthorized use and access in your business applications
  
export default function AWS() {
  useEffect(() =>{
    document.title = 'Amazon AWS Cloud Asset Protection';
    window.scrollTo(0,0)
   })
  return (
    <>
    <Box
      minHeight="30rem"
      width="100%"
      variant="gradient"
      bgcolor="darkgreen.main"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
  <Grid container maxWidth="lg" spacing={2} p={2} mt={8}  sx={{ minHeight: "350px"}} >
    <Grid item xs={12} md={6} p={2}>
      <Typography variant="h2" color="white.main" fontWight='fontWeightMedium' mt={4}>
        Asset Protection for Amazon AWS.
      </Typography>
      <Link to="/contact-us">
            <Button 
                sx={{ marginTop: '50px'}}
                id="learnmore-button"
                disableElevation
                color="light"
                key="learnmore"
                variant="contained" 
                size="lg">
                    <Typography sx={{ fontSize: "1.3em" }} color="black.main">Contact Us For Beta Info</Typography>
            </Button>
          </Link>
    </Grid>
    <Grid item xs={0} md={6} >
          <Box  style={{
            backgroundImage: `url(${amazonAWS})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
          }}> 
            
          </Box>
      </Grid>
  </Grid>
  </Box>
  <Box
  minHeight="40rem"
  width="100%"
  variant="gradient"
  sx={{
    backgroundSize: "cover",
    backgroundPosition: "top",
    display: "grid",
    placeItems: "center",
    background: '#0f4d2d'
  }}
  >
  <Grid container maxWidth="lg" spacing={2}  mb={2}>
    <Grid item xs={12} md={5} sx={{ alignItems: "right"}} p={2} mt={2} ml={2}>
      <Typography variant="h4" color="grey.100">
        Detect exploits due to misconfiguration, service vulnerability, and insider threats in minutes.
      </Typography>
      <Typography variant="h5" color="grey.300" mt={2}>
        Eliminate the primary security concerns in AWS with AI-driven breach detection from Trawl AI.
      </Typography>
      <Typography variant="body2" color="grey.300" mt={2}>
        Trawl AI protects your AWS services from exploits by collecting meta-data, 
        compiling key data points, and detecting risks through anomalous activity and potential access
        through publicly facing services.  
      </Typography>
    </Grid>
    <Grid md={1} />
        <Grid item xs={12} md={5} sx={{ backgroundColor: "white" }}>
          <Box
            minHeight="100%"
            bgcolor="darkgreen.focus" 
            p={4}

            >
              <Typography variant="h6" color="grey.300">
                Misconfiguration
              </Typography>
              <Typography variant="body2" color="grey.400">
                <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                  <ListItem sx={{ display: 'list-item' }}>Publicly accessible S3 buckets</ListItem>
                  <ListItem sx={{ display: 'list-item' }}>Overly broad user permissions</ListItem>
                  <ListItem sx={{ display: 'list-item' }}>Weak root credentials IAM misconfiguration</ListItem>
                </List>
              </Typography>
              <Typography variant="h6" color="grey.300">
                Insider Threats
              </Typography>
              <Typography variant="body2" color="grey.400">
                <List sx={{ listStyleType: 'disc', pl: 6 }} dense>
                  <ListItem sx={{ display: 'list-item' }}>Users with excessive access</ListItem>
                  <ListItem sx={{ display: 'list-item' }}>Unintentional data exposure</ListItem>
                  <ListItem sx={{ display: 'list-item' }}>Unpatched vulnerabilities</ListItem>
                </List>
              </Typography>
          </Box>
        </Grid>
  </Grid>
</Box>
<Box
      minHeight="40rem"
      width="100%"
      variant="gradient"
      bgcolor="grey.100"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
     <Grid container maxWidth="lg" mb={3} mt={4}>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6} p={2} >
          <Typography variant="h4" sx={{ fontWeight: 700 }} color="trawlgrey">
            Public Beta Coming Soon
          </Typography>
          <Typography variant="h5" sx={{ fontSize: "1.4em"}} color="trawlgrey" mt={4} >
            Check back often or <Typography display="inline" variant="h5" color="darkgreen.main" component={Link} to="/contact-us">contact us</Typography> today for more information on our private Amazon AWS
            beta availability. Or check out the other services and early access in our Roadmap.
          </Typography>
          
        </Grid>
     </Grid>
    
    </Box>
</>

  );
}


