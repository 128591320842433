import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import smallLogo from 'assets/images/logos/trawl logo 2022 small.png';
import {Link} from 'react-router-dom';
import { LinkedIn,Twitter } from "@mui/icons-material";

export default function Footer() {
    return(
            <Grid container >
                <Grid item xs={12} >
                    <Box 
                        minHeight="20rem"
                        width="100%"
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                            background: 'linear-gradient( #e6e6e6, #cacaca)',
                        }}
                    >
                        <Grid container maxWidth="lg" spacing={2} ml={5} mt={10} >
                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Link to="/" >
                                            <img src={smallLogo}  />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="caption">
                                                    Trawl AI is bringing intelligent threat detection to Microsoft 365 to quickly end unauthorized access to Email, Teams, SharePoint, and other Azure services.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to="https://twitter.com/trawlai" target="_blank"><Typography mr={2} color="grey.900" variant="caption"><Twitter /></Typography></Link>
                                        <Link to="https://twitter.com/trawlai" target="_blank"><Typography color="grey.900" variant="caption"><LinkedIn /></Typography></Link>
                                        
                                    </Grid>
                                </Grid>
    
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1" color="dark">Platform</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/ms365" variant="caption" color="darkgreen.main">Microsoft 365</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/azure" variant="caption" color="darkgreen.main">Microsoft Azure</Typography>    
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/aws" variant="caption" color="darkgreen.main">Amazon AWS</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/workspace" variant="caption" color="darkgreen.main">Google Workspace</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/gcp" variant="caption" color="darkgreen.main">Google GCP</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/products/siem" variant="caption" color="darkgreen.main">SIEM</Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1" color="dark">About</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/about" variant="caption" color="darkgreen.main">About Trawl AI</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/contact-us" variant="caption" color="darkgreen.main">Contact Us</Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1" color="dark">Who We Serve</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/managed-partners" variant="caption" color="darkgreen.main">Managed Services Providers</Typography>
                                <Typography component={Link} display="block" style={{ textDecoration: 'none'}} to="/smb" variant="caption" color="darkgreen.main">Small & Medium Businesses</Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Typography variant="body1" color="darkgreen.main" style={{ textDecoration: 'none'}} component={Link} to="/blog">Blog</Typography>
                            </Grid>
                            <Grid item xs={12} mb={4}>
                                <Copyright />
                            </Grid>
                        </Grid> 
                    </Box>
                </Grid>
            </Grid>
    );
}