import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


// Intelligently detecting unauthorized use and access in your business applications
  
export default function AboutUs() {
  useEffect(() =>{
    document.title = 'About Trawl AI - Securing the cloud through early detection';
    window.scrollTo(0,0)
 })
  return (
    <>
    <Box
      minHeight="30rem"
      width="100%"
      variant="gradient"
      bgcolor="darkgreen.main"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
  <Grid container maxWidth="lg" spacing={2} p={2} mt={8}>
    <Grid item xs={12} md={6} p={2}>
      <Typography variant="h2" color="white.main" fontWight='fontWeightMedium'>
              We find what others fail to prevent 
            </Typography>
            <Typography
              variant="body1"
              color="white.main"
              mt={2}
            >
              Generative AI is making phishing smarter. New Multi-Factor bypass methods are discovered frequently. Know when your security measures fail and attacks are succesful
              faster with Trawl AI than with any other method.
            </Typography>
          </Grid>

        </Grid>
      </Box>
      
    
        <Grid container justifyContent="center" alignItems="center" p={2} mb={5} mt={10}>
                <Grid item xs={12} md={4} textAlign="">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2}> 
                        Accessible. Secure. Automated.
                    </Typography>
                    <Typography variant="body" color="dark" mb={10} align="">
                        Trawl AI is making intelligent cloud security accessible to everyone through a simple subscription process, fully automated configuration of checks and alerts, 
                        and an optional Security Ops center service to react immediately to detected threats throughout your services. This provides a cost effective security fail-safe 
                        for MSPs and direct cloud service users. 
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" p={2} spacing={2} mb={10} mt={10}>
                <Grid item xs={12} md={4} justifyContent="center">
                    <Typography variant="h4" sx={{ fontWeight: 700 }} mb={2} >
                        The Beginning
                    </Typography>
                    <Typography variant="body1" color="dark" mb={4} align="">
                        Trawl AI began as an agent-based data center security correlation system that would identify anomalous usage throughout infrastructure services.<br /><br />
                        The beginning of the pandemic accelerated adoption of cloud services like Microsoft 365 and Google Workspace, and recognizing that, we pivoted to focus 
                        on those services to protect smaller businesses who quickly became the target of threat actors across the globe. 
                    </Typography>
                </Grid>
            </Grid>
     
    </>
  );
}

