import React from "react";
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Routes, Redirect, useLocation, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

/* import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl"; */
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";


// import routes from "routes";

// import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";

// import Dashboard from "layouts/dashboard";
import NotFound from "components/NotFound";
import Main from "components/Main/Main";
import Index from "components/Main/Index";
import Microsoft365 from "components/Main/Products/Microsoft365";
import Azure from "components/Main/Products/Azure";
import Workspace from "components/Main/Products/Workspace";
import GCP from "components/Main/Products/GCP";
import AWS from "components/Main/Products/AWS";
import SIEM from "components/Main/Products/SIEM";
import BlogMain from "components/Blog/BlogMain";
import BlogIndex from "components/Blog/BlogIndex";
import ContactUs from "components/Main/ContactUs";
import AboutUs from "components/Main/AboutUs";
import ManagedPartners from "components/Main/ManagedPartners";
import SMBs from "components/Main/SMBs";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-488FP5YWK8";
ReactGA.initialize(TRACKING_ID)

// import SignIn from "layouts/authentication/sign-in"

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return  (
    /* 
    <ThemeProvider theme={theme}>
      <PrivateRoute path="/dashboard"/>
      <PrivateRoute path="/company" />
      <PrivateRoute path="/user" />
      <PrivateRoute path="/azure" />      
      <PrivateRoute path="/google" />
      <PrivateRoute path="/amazon" />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Trawl AI"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
      </Routes>
    </ThemeProvider>

    <Route path="/" element={<Navigate to="/app" />} />

     <Route element={<Dashboard />}>
        <Route path="/" element={ <Home />} />
      </Route>
    */
    
    <Routes>
      <Route element={<Main />} >
        <Route path="/" element={<Index />} />
        <Route path="/products/ms365" element={<Microsoft365 />} />
        <Route path="/products/azure" element={<Azure />} />
        <Route path="/products/gcp" element={<GCP />} />
        <Route path="/products/aws" element={<AWS />} />
        <Route path="/products/workspace" element={<Workspace />} />
        <Route path="/products/siem" element={<SIEM />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/managed-partners" element={<ManagedPartners />} />
        <Route path="/smb" element={<SMBs />} />
        <Route element={<BlogMain />}>
          <Route path="/blog" element={<BlogIndex />} />
        </Route>
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  
}

