import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import trawlLogo from "assets/images/logos/trawl logo large 2022.png"
import Copyright from 'components/Copyright/Copyright';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import colors from "assets/theme/base/colors";

const defaultTheme = createTheme({
  palette: {...colors}
});

export default function NotFound() {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)


  const handleSubmit = (event) => {

    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    AuthService.login(data.get('email'), data.get('password')).then(
      (res) => {
        console.log(res)
        setLoginFailed(false)
        setLoading(false)
        nav("/app")
      })
      .catch( (err) => {
        setLoginFailed(true)
        setLoading(false)
      })
    
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <img src={trawlLogo} alt="Trawl AI" width="450px" />
          <Typography component="h1" variant="h1" mt={8}>
            404
          </Typography>
          <Typography component="h1" variant="h4" mt={4} mb={5}>
            Page Not Found
          </Typography>
          <Link onClick={() => nav(-1)}  >
            <Button 
                      id="gohomebutton"
                      color="light"
                      key="gohome"
                      variant="text" 
                      >
                          <Typography variant="h6" sx={{ fontSize: "1.3em", textDecoration: 'underline' }} color="darkgreen.main">Go Back</Typography>
            </Button>
          </Link>
          <Link href="/">
            <Button 
                      id="gohomebutton"
                      color="light"
                      key="gohome"
                      variant="text" 
                      >
                          <Typography variant="h6" sx={{ fontSize: "1.3em", textDecoration: 'underline' }} color="darkgreen.main">Go Home</Typography>
            </Button>
          </Link>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}